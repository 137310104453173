import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  withApi,
  withUser,
  withApps,
  withHomey,
  withMessages,
} from '../../services/AthomApi';
import Page from '../Page';
import Card from '../Card';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import WarningIcon from '@material-ui/icons/Warning';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import TextField from '@material-ui/core/TextField';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { curveLinear } from "d3-shape";
import { timeFormat } from "d3-time-format";
import { select } from 'd3-selection';

import MetricsGraphics from '../MetricsGraphics';

import TimeAgo from 'react-timeago';

import './style.css';

import 'metrics-graphics/dist/metricsgraphics.css';
import '../../metricsgraphics-dark.css';
import { FormControlLabel, Switch } from '@material-ui/core';

const APP_STATES = {
  'waiting_for_files': 'Waiting for files',
  'processing': 'Processing',
  'processing_failed': 'Processing failed',
  'draft': 'Draft',
  'test': 'Test',
  'in_review': 'In Review',
  'reviewed_approved': 'Review Approved',
  'reviewed_rejected': 'Review Rejected',
  'live': 'Live',
  'superseded': 'Superseded',
}

const formatTime = timeFormat('%b %d, %Y');

const BUILDS_ROWS_PER_PAGE = 5;
const SUGGESTIONS_ROWS_PER_PAGE = 20;

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onPageChange(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onPageChange(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onPageChange(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onPageChange(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
});

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

class InstallDialog extends Component {

  handleInstall = () => {
    const id = this.props.app.id;
    const build = this.props.build;

    let channel;
    if (build.state === 'live') {
      channel = 'stable';
    } else {
      channel = 'beta';
    }

    this.props.homey.apps.installFromAppStore({ id, channel })
      .then(() => {
        this.props.handleSuccess(`${id} will install shortly...`);
      })
      .catch(err => {
        this.props.handleError(err);
      })
      .finally(() => {
        this.props.onClose();
      });
  }

  render() {
    if (!this.props.build) return null;
    if (!this.props.homey) return null;

    if (!this.props.build.platforms.includes(this.props.homey.platform)) return (
      <Fragment>
        <DialogContent>
          <DialogContentText>The app <strong>{this.props.app.id}</strong> is not compatible with <strong>{this.props.homey.name}</strong> because platform <strong>{this.props.homey.platform}</strong> is missing from the app's manifest.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Fragment>
    );

    return (
      <Fragment>
        <DialogContent>
          <DialogContentText>
            You're about to install <strong>{this.props.app.id}@{this.props.build.version}</strong> on <strong>{this.props.homey.name}</strong>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>
            Cancel
          </Button>
          <Button onClick={this.handleInstall} color="primary">
            Install
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
};

const InstallDialogWithHomey = withSnackbar(withMessages(withApi(withUser(withApps(withHomey(InstallDialog))))));

class PageAppsApp extends Component {

  state = {
    appInstallStats: [],
    driversStats: {},

    builds: null,
    buildsPage: 0,

    suggestions: null,
    suggestionsPage: 0,

    dialogDeleteAppOpen: false,
    dialogTransferOpen: false,
    dialogInstallAppOpen: false,
    dialogInstallAppBuild: null,
    transferUserId: null,

    settings: null,
  }

  componentDidMount() {
    this.handleRefreshApps();
    this.handleRefreshAppInstallStats();
    this.handleRefreshBuilds();
    this.handleRefreshSuggestions();
    this.handleRefreshDriversStats();
    this.handleRefreshSettings();
  }

  handleRefreshSettings = () => {
    const { appId } = this.props.match.params;

    this.props.callApps('getAppSettings', {
      appId,
    }).then(settings => {
      this.setState({ settings });
    }).catch(this.props.handleError);
  }

  handleRefreshApps = () => {
    const { appId } = this.props.match.params;

    this.props.callApps('getApp', { appId }).then(app => {
      this.setState({ app });
    }).catch(this.props.handleError);

  }

  handleRefreshAppInstallStats = () => {
    const { appId } = this.props.match.params;

    this.props.callApps('getAppInstallStats', {
      appId,
    }).then(appInstallStats => {
      let notNullValues = 0;

      appInstallStats = appInstallStats.map((d, i) => {
        if (d.value) notNullValues++; // Count all values which are not null
        d.date = new Date(d.date);
        return d;
      });

      // If there is only one datapoint, add this as an "active" datapoint (showing a point)
      if (notNullValues === 1) {
        appInstallStats[appInstallStats.length - 1].active = true;
      }

      this.setState({ appInstallStats });
    }).catch(this.props.handleError);
  }

  handleRefreshBuilds = () => {
    const { appId } = this.props.match.params;

    this.props.callApps('getBuilds', {
      appId,
    }).then(builds => {
      this.setState({ builds });
    }).catch(this.props.handleError);
  }

  handleRefreshSuggestions = () => {
    const { appId } = this.props.match.params;

    this.props.callApps('getAppSuggestions', {
      appId,
    }).then(suggestions => {
      suggestions.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      this.setState({ suggestions });
    }).catch(this.props.handleError);
  }

  handleRefreshDriversStats = () => {
    const { appId } = this.props.match.params;

    this.props.callApps('getAppDriversStats', {
      appId,
    }).then(drivers => {
      const driversStats = {};
      for (const [driverId, data] of Object.entries(drivers)) {
        let notNullValues = 0;

        driversStats[driverId] = data.map((d, i) => {
          if (d.value) notNullValues++; // Count all values which are not null
          d.date = new Date(d.date);
          return d;
        });

        // If there is only one datapoint, add this as an "active" datapoint (showing a point)
        if (notNullValues === 1) {
          driversStats[driverId][driversStats[driverId].length - 1].active = true;
        }
      }

      this.setState({ driversStats });
    }).catch(this.props.handleError);
  }

  handleChangeBuildsPage = (event, buildsPage) => {
    this.setState({ buildsPage });
  };

  handleChangeSuggestionsPage = (event, suggestionsPage) => {
    this.setState({ suggestionsPage });
  };

  handleDeleteApp = (event) => {
    this.props.callApps('deleteApp', {
      appId: this.state.app.id,
    }).then(() => {
      this.props.handleSuccess(`App '${this.state.app.id}' deleted successfully`);
      this.props.history.push('/apps');
    }).catch(this.props.handleError);
  }

  handleDialogDeleteAppOpen = () => {
    this.setState({ dialogDeleteAppOpen: true });
  };

  handleDialogDeleteAppClose = () => {
    this.setState({ dialogDeleteAppOpen: false });
  };

  handleTransferApp = () => {
    const { appId } = this.props.match.params;
    const { transferUserId } = this.state;

    Promise.resolve().then(async () => {
      if (!transferUserId || transferUserId.length !== 24)
        throw new Error('Invalid User ID');

      await this.props.callApps('updateAppAuthor', {
        appId,
        userId: transferUserId,
      })
    }).then(() => {
      this.props.handleSuccess('App transfer request sent.');
      this.handleDialogTransferClose();
    }).catch(this.props.handleError)
  };

  handleDialogTransferOpen = () => {
    this.setState({ dialogTransferOpen: true });
  };

  handleDialogTransferClose = () => {
    this.setState({ dialogTransferOpen: false });
  };

  handleInstallDialogOpen = build => {
    this.setState({
      dialogInstallAppOpen: true,
      dialogInstallAppBuild: build,
    });
  }

  handleInstallDialogClose = () => {
    this.setState({
      dialogInstallAppOpen: false,
      dialogInstallAppBuild: null,
    });
  }

  handleSendEmailSuggestionChange = (e, value) => {
    const { appId } = this.props.match.params;

    this.props.callApps('updateAppSettings', {
      appId,
      sendEmailSuggestion: value,
    }).then(() => {
      this.setState({
        settings: {
          ...this.state.settings,
          sendEmailSuggestion: value,
        },
      });
    }).catch(this.props.handleError);
  }

  render() {
    const {
      app,
      appInstallStats,
      builds,
      buildsPage,
      driversStats,
      suggestions,
      suggestionsPage,
      dialogDeleteAppOpen,
      dialogTransferOpen,
      dialogInstallAppOpen,
      dialogInstallAppBuild,
      settings,
    } = this.state;

    builds && builds.sort((a, b) => {
      return b.id - a.id;
    });

    /**
     * Format x-label.
     * @param {Date} date - x value.
     * @returns {string}
     */
    function formatYLabel(n) {
      let stars = '';
      for (var i = 0; i < n; i++)
        stars += '★ '

      return stars;
    }

    return (
      <Page className="AppsApp" cards>

        {app && (
          <Card>
            <Typography variant="h5" color="textPrimary" gutterBottom={true}>
              <Link className="AppsHeaderLink" to={`/apps`}>My Apps</Link>
              <NavigateNextIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
              <Link className="AppsHeaderLink" to={`/apps/app/${app.id}`}>{app.id}</Link>
            </Typography>

            <Typography variant="body2" gutterBottom={true}>
              Local Installs: {app.installsLocal}<br />
              Cloud Installs: {app.installsCloud}<br />
            </Typography>

            {appInstallStats.length === 0 && (
              <MetricsGraphics
                chart_type="missing-data"
                missing_text={"No install data is currently available."}
                full_width={true}
                height={250}
              />
            )}

            {appInstallStats.length > 0 && (
              <MetricsGraphics
                data={appInstallStats}
                full_width={true}
                interpolate={curveLinear}
                y_extended_ticks={true}
                top={20}
                left={50}
                height={250}
                min_x={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                max_x={new Date()}
                point_size={3}
                active_point_on_lines={true}
                active_point_accessor="active"
                active_point_size={2}
                min_y={0}
                xax_count={12}
                x_accessor="date"
                y_accessor="value"
                area={true}
                show_secondary_x_label={true}
                mouseover={function (d, i) {
                  var df = timeFormat('%b %d, %Y');
                  var date = df(d.date);
                  var y_val = (d.value === 0) ? 'no data' : parseFloat(d.value).toFixed(0);

                  select('.mg-active-datapoint')
                    .text(date + '   Installs: ' + y_val);
                }}
              />
            )}
          </Card>
        )}

        {app && (
          <Card title="Builds">
            {!builds && (
              <CircularProgress />
            )}

            {builds && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[BUILDS_ROWS_PER_PAGE]}
                      colSpan={10}
                      count={builds.length}
                      rowsPerPage={BUILDS_ROWS_PER_PAGE}
                      page={buildsPage}
                      SelectProps={{
                        native: true,
                      }}
                      onPageChange={this.handleChangeBuildsPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell>Icon</TableCell>
                    <TableCell>Build</TableCell>
                    <TableCell>Version</TableCell>
                    <TableCell>SDK</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Last Updated</TableCell>
                    <TableCell>Installs</TableCell>
                    <TableCell>Crashes</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {builds.slice(buildsPage * BUILDS_ROWS_PER_PAGE, buildsPage * BUILDS_ROWS_PER_PAGE + BUILDS_ROWS_PER_PAGE).map(build => (
                    <TableRow
                      key={build.id}
                      className={build.state === 'superseded' ? 'Superseded' : ''}
                    >
                      <TableCell>
                        <Link
                          style={{ textDecoration: 'none' }}
                          to={`/apps/app/${app.id}/build/${build.id}`}
                        >
                          <div className="HomeyIcon" style={{
                            backgroundColor: build.brandColor,
                          }}>
                            <div className="HomeyIconInner" style={{
                              maskImage: `url(${build.icon})`,
                              WebkitMaskImage: `url(${build.icon})`,
                              MozMaskImage: `url(${build.icon})`,
                            }}></div>
                          </div>
                        </Link>
                      </TableCell>
                      <TableCell>#{build.id}</TableCell>
                      <TableCell>{build.version}</TableCell>
                      <TableCell>{build.sdk}</TableCell>
                      <TableCell>{build.name ? build.name.en : '-'}</TableCell>
                      <TableCell>
                        {build.state === 'processing_failed' && (
                          <WarningIcon
                            onClick={() => {
                              window.alert(build.stateMeta);
                            }}
                            color="error"
                            fontSize="small"
                            style={{
                              cursor: 'pointer',
                              verticalAlign: 'middle',
                            }}
                          />
                        )}
                        {APP_STATES[build.state] || build.state}
                      </TableCell>
                      <TableCell title={build.stateChangedAt}>
                        {formatTime(new Date(build.stateChangedAt))}
                      </TableCell>
                      <TableCell>
                        {build.installs}
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/apps/app/${app.id}/build/${build.id}/crashes`}
                          style={{
                            textDecoration: 'none',
                            color: 'rgba(  0, 130, 250, 1.00)',
                          }}
                        >
                          {build.crashes}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          style={{ textDecoration: 'none' }}
                          to={`/apps/app/${app.id}/build/${build.id}`}
                        >
                          <Button color="primary">
                            {'Submission'}
                            <NavigateNextIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
                          </Button>
                        </Link>

                        {build.state === 'live' && (
                          <a
                            href={`https://homey.app/a/${app.id}`}
                            style={{ textDecoration: 'none' }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button color="primary">
                              {'App Store'}
                              <OpenInNewIcon fontSize="small" style={{ verticalAlign: 'middle', marginLeft: 10, }} />
                            </Button>
                          </a>
                        )}

                        {app.liveBuild && (build.state === 'test' || build.state.includes('review')) && (
                          <a
                            href={`https://homey.app/a/${app.id}/test`}
                            style={{ textDecoration: 'none' }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button color="primary">
                              {'App Store'}
                              <OpenInNewIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
                            </Button>
                          </a>
                        )}

                        {((app.testBuild && app.testBuild.id === build.id) || (app.liveBuild && app.liveBuild.id === build.id)) && (
                          <Button color="primary" onClick={() => this.handleInstallDialogOpen(build)}>
                            {'Install'}
                            <CloudDownloadIcon fontSize="small" style={{ verticalAlign: 'middle', marginLeft: 10, }} />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[BUILDS_ROWS_PER_PAGE]}
                      colSpan={10}
                      count={builds.length}
                      rowsPerPage={BUILDS_ROWS_PER_PAGE}
                      page={buildsPage}
                      SelectProps={{
                        native: true,
                      }}
                      onPageChange={this.handleChangeBuildsPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </Card>
        )}

        {app && (
          <Card title="Devices" subtitle="The total number of connected devices (Homey Pro only for now).">
            {!driversStats && (
              <CircularProgress />
            )}

            {driversStats && Object.keys(driversStats).length === 0 && (
              <MetricsGraphics
                chart_type="missing-data"
                missing_text={"This app does not seem to have drivers, or no data is available."}
                full_width={true}
                height={250}
              />
            )}

            {driversStats && Object.keys(driversStats).length > 0 && (
              <MetricsGraphics
                data={Object.values(driversStats)}
                // legend={Object.keys(driversStats)}
                full_width={true}
                interpolate={curveLinear}
                y_extended_ticks={true}
                top={20}
                left={80}
                height={250}
                min_x={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                max_x={new Date()}
                point_size={3}
                active_point_on_lines={true}
                active_point_accessor="active"
                active_point_size={2}
                min_y={0}
                xax_count={12}
                x_accessor="date"
                y_accessor="value"
                area={true}
                show_secondary_x_label={true}
                mouseover={function (d, i) {
                  var df = timeFormat('%b %d, %Y');
                  var date = df(d.date);
                  var y_val = (d.value === 0) ? 'n/a' : d.value;

                  const driverId = Object.keys(driversStats)[d.index - 1];

                  select('.mg-active-datapoint').text(`${date} — ${y_val} [${driverId}]`);
                }}
              />
            )}
          </Card>
        )}

        {app && (
          <Card
            title="Suggestions"
            subtitle="Feedback from your users."
            toolbar={settings && <FormControlLabel
              labelPlacement="start"
              label="Notify by Email"
              control={<Switch
                color="primary"
                checked={settings.sendEmailSuggestion}
                onChange={this.handleSendEmailSuggestionChange}
              />}
            />}
          >
            {!suggestions && (
              <CircularProgress />
            )}

            {suggestions && suggestions.length === 0 && (
              <Typography variant="body2" color="textSecondary" align="center" style={{ padding: '1em 0' }}>There are no suggestions yet.</Typography>
            )}

            {suggestions && suggestions.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[SUGGESTIONS_ROWS_PER_PAGE]}
                      colSpan={10}
                      count={suggestions.length}
                      rowsPerPage={SUGGESTIONS_ROWS_PER_PAGE}
                      page={suggestionsPage}
                      SelectProps={{
                        native: true,
                      }}
                      onPageChange={this.handleChangeSuggestionsPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Text</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {suggestions.slice(suggestionsPage * SUGGESTIONS_ROWS_PER_PAGE, suggestionsPage * SUGGESTIONS_ROWS_PER_PAGE + SUGGESTIONS_ROWS_PER_PAGE).map(suggestion => (
                    <TableRow
                      key={suggestion.id}
                    >
                      <TableCell style={{
                        width: '1%',
                        whiteSpace: 'nowrap',
                      }} title={suggestion.createdAt}><TimeAgo date={suggestion.createdAt} /></TableCell>
                      <TableCell>{suggestion.suggestionText}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Card>
        )}

        {app && app.liveBuild && (
          <Card
            title="Transfer app"
            subtitle="You can transfer your app to another developer."
          >
            <p>&nbsp;</p>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleDialogTransferOpen}
            >
              <SwapHorizIcon />
              {'Transfer App'}
            </Button>
          </Card>
        )}

        {app && ((!app.testBuild && !app.liveBuild) || this.props.user.roleIds.includes('app_reviewer')) && (
          <Card
            title="Delete app"
            subtitle="You can delete your app while it's still unpublished."
          >
            <p>&nbsp;</p>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleDialogDeleteAppOpen}
            >
              <DeleteIcon />
              {'Delete App'}
            </Button>
          </Card>
        )}

        {app && (
          <Dialog
            open={dialogDeleteAppOpen}
            onClose={this.handleDialogDeleteAppClose}
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle>{`Are you sure you want to remove "${app.id}"?`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`This app will no longer be available for users to install.`}<br />
                {`Existing users won't be affected.`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogDeleteAppClose}>
                Cancel
              </Button>
              <Button onClick={this.handleDeleteApp} color="secondary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {app && (
          <Dialog
            open={dialogTransferOpen}
            onClose={this.handleDialogTransferClose}
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle>{`Are you sure you want to transfer "${app.id}"?`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`This app will be transferred to another developer.`}<br />
                {`The new developer has one day to accept your request before it expires.`}
              </DialogContentText>
              <TextField
                autoFocus
                inputProps={{
                  minLength: 24,
                  maxLength: 24,
                }}
                onChange={e => {
                  this.setState({
                    transferUserId: e.target.value,
                  })
                }}
                margin="normal"
                label="New owner User ID"
                placeholder="5531600e09adaac12abd4c3a"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogTransferClose}>
                Cancel
              </Button>
              <Button onClick={this.handleTransferApp} color="secondary">
                Transfer
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {app && <Dialog
          open={dialogInstallAppOpen}
          onClose={this.handleInstallDialogClose}
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle>{`Install App`}</DialogTitle>
          <InstallDialogWithHomey
            app={app}
            build={dialogInstallAppBuild}
            onClose={this.handleInstallDialogClose}
          />
        </Dialog>}

      </Page>
    );
  }
}

export default withSnackbar(withMessages(withApi(withUser(withApps(PageAppsApp)))));
